<template>
  <div class="app-container">
    <paper-page></paper-page>
    <!-- <el-tabs>
        <el-tab-pane label="论文视角">
            <paper-page></paper-page>
        </el-tab-pane>
        <el-tab-pane label="评阅书视角">
            <review-page></review-page>
        </el-tab-pane>
        <el-tab-pane label="专家视角">
            <expert-page></expert-page>
        </el-tab-pane>
        <el-tab-pane label="总体视角">
            <total-page></total-page>
        </el-tab-pane>
    </el-tabs> -->
  </div>
</template>

<script>
import PaperPage from '@/views/settlement/PaperPage2.vue'
import ReviewPage from '@/components/p2p/ReviewPage'
import ExpertPage from '@/components/p2p/ExpertPage'
import totalPage from '@/components/p2p/totalPage'
export default {
  name: 'list',
  data() {
    return {

    }
  },
  methods: {

  },
  components: {
    PaperPage,
    ReviewPage,
    ExpertPage,
    totalPage
  }
}
</script>

<style scoped>

</style>